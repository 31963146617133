.App-header {
  background-color: #222;
  height: 50px;
  padding: 10px;
	color: white;
	text-align: center;
}

.App-title {
  font-size: 1.5em;
  margin-top: 0px;
}

.repo-btn {
  width: 130px;
  height: 60px;
}